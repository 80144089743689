import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

export const NewPasswordPage = () => {
  return (
    <div className="login-page register">


      <Container fluid className="px-5 logo-wrapper">
        <div className="logo">
          <img src="/assets/img/logo-sandoz.png" alt="Logo Sandoz" />
        </div>
      </Container>

      <Form className='mt-5'>
        <Container className='mt-3'>

          <Row>
            <Col lg={12}>
              <h1 className="fw-bold font-xxl my-4 pb-2">Reestablecer contraseña</h1>

            </Col>
            <Col lg={8}>
              <p className="">Puedes establecer una nueva contraseña para tu cuenta:</p>
             
            </Col>
          </Row>

          <Row className="mt-5">
            <Col lg={6}>

            <Form.Group className="form-group" controlId="password">
                <Form.Label>Nueva contraseña</Form.Label>
                <Form.Control type="text" placeholder="Contraseña" />
                
              </Form.Group>
              <Form.Group className="form-group" controlId="repeatPassword">
                <Form.Label>Confirmar nueva contraseña</Form.Label>
                <Form.Control type="text" placeholder="Repetir contraseña" />
                
              </Form.Group>

            </Col>

          </Row>

          <Row>
            <Col lg={12}>
              <Button className="prim my-4" variant="none" type="submit" >
                Confirmar nueva contraseña
                 </Button>
            </Col>
          </Row>




        </Container>
      </Form>



    </div>
  )
}
