import { Container, Row, Col } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import { recoveryValidationSchema } from '../../../helpers/schemas/authSchema';
import { InputField } from '../../components/shared/fields';
import { BasicLoader } from '../../components/shared/loaders';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { useAuth } from '../../customHooks/useAuth';

export const ResetPasswordPage = () => {
  const { recovery } = useAuth()
  const { t } = useTranslation()

  return (
    <div className="login-page register">
     
      <Formik
        initialValues={{ email: "" }}
        validationSchema={recoveryValidationSchema}
        onSubmit={(fields, actions) => recovery(fields, actions.setSubmitting)}
      >
        {
          (props) => {
            return (
              <Form>
                <Container fluid>
                  <Row>
                    <Col lg={12} className="ps-lg-5">
                      <div className="logo">
                        <img src="/assets/img/logo-sandoz-full.png" alt="Logo Sandoz" />
                      </div>

                    </Col>
                  </Row>
                </Container>
                <Container>
                  <Row>
                    <Col lg={12}>
                      <h1 className="fw-bold font-xxl mb-4 mt-5 pt-4 pb-2">{t('recovery.recoveryPassword')}</h1>
                    </Col>
                    <Col lg={8}>
                      <p>{t('recovery.ifHaveForgotPasswordHeadline')}</p>
                    </Col>
                  </Row>
                  <Row className="mt-5">
                    <Col lg={6}>
                      <InputField {...props} type="text" label={t('user.email')} name="email" />
                    </Col>

                  </Row>
                  <Row>
                    <Col lg={12}>
                      <Button className="prim my-4" variant="none" type="submit" >
                        {
                          props.isSubmitting ? <BasicLoader /> : t('general.confirm')
                        }
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </Form>
            )
          }
        }
      </Formik>
    </div>
  )
}
